<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">赞助产品列表</p>
            <p class="btn-wrap-width" @click="eidtContentInfo(null)">新增赞助产品</p>
        </div>
        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-name">赞助产品名称</div>
                <div class="table-type">赞助类型</div>
                <div class="table-limit">名额数量限制</div>
                <div class="table-quota">赞助产品名额</div>
                <div class="table-amount">赞助金额</div>
                <div class="table-operation">操作</div>
            </div>
            <div v-for="(content, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{ (index + 1) }}</div>
                <div class="table-name" style="font-weight: bold;">{{ content.name }}</div>
                <div class="table-type" style="font-weight: bold;">{{ content.type }}</div>
                <div class="table-limit" style="font-weight: bold;">{{ content.quota.limit == 'yes' ? '限制' : '不限制' }}
                </div>
                <div class="table-quota">{{ content.quota }}</div>
                <div class="table-amount">{{ content.amount }}</div>
                <div class="table-operation" style="display: flex;">
                    <p class="list-btn-blue" style="text-align: left;padding-left: 8px;"
                        @click="eidtContentInfo(content)">
                        编辑</p>
                    <p class="list-btn-red" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                        @click="doRemoveContent(content)">删除</p>

                </div>
            </div>
        </div>
        <el-dialog :visible.sync="showContentInfoDialog" :title="contentInfo.id == '' ? '添加赞助产品' : '编辑赞助产品'"
            width="65%">
            <div>
                <el-form :model="contentInfo" :rules="rules" ref="contentForm">
                    <el-form-item label="赞助产品名称" prop="name">
                        <el-input v-model="contentInfo.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="赞助产品类型" prop="type">
                        <el-select v-model="contentInfo.type" placeholder="请选择">
                            <el-option label="演讲赞助" value="演讲赞助"></el-option>
                            <el-option label="铂金赞助" value="铂金赞助"></el-option>
                            <el-option label="钻石赞助" value="钻石赞助"></el-option>
                            <el-option label="独家冠名赞助" value="独家冠名赞助"></el-option>
                            <el-option label="首席赞助" value="首席赞助"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="产品名额数量限制" prop="quota_limit">
                        <el-radio v-model="contentInfo.quota_limit" label="yes">限制数量</el-radio>
                        <el-radio v-model="contentInfo.quota_limit" label="no">不限制数量</el-radio>
                    </el-form-item>
                    <el-form-item label="赞助产品名额" prop="quota" v-if="contentInfo.quota_limit == 'yes'">
                        <el-input-number v-model="contentInfo.quota" placeholder="请输入"></el-input-number> (个)
                    </el-form-item>
                    <el-form-item label="赞助金额" prop="amount">
                        <el-input-number v-model="contentInfo.amount" placeholder="请输入"></el-input-number>（单位元）
                    </el-form-item>
                    <el-form-item label="">
                        <label>赞助权益</label>
                        <quill-editor class="editor" style="margin-bottom:20px" ref="myTextEditor"
                            v-model="contentInfo.rights" :options="editorOption">
                        </quill-editor>
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showContentInfoDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveContent()">确 定</el-button>
                    </span>
                </div>
            </div>

        </el-dialog>

    </div>
</template>
<script>
import {
    getSponsorContents,
    updateSponsorContent,
    removeSponsorContent
} from '../../api/api'
import '../../assets/common/common.css'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            contentInfo: {
                id: '',
                meeting_id: this.meeting_id,
                name: '',
                type: '',
                quota_limit: '',
                quota: '',
                amount: '',
                rights: ''
            },
            rules: {
                quota_limit: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
                quota: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                amount: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ]
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video'] // 链接、图片、视频
                    ] // 工具菜单栏配置
                },
                placeholder: '', // 提示
                readyOnly: false, // 是否只读
                theme: 'snow', // 主题 snow/bubble
                syntax: true // 语法检测
            },
            showContentInfoDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getSponsorContents(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemoveContent(row) {
            this.$alert('你确定要删除此赞助产品吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeSponsorContent(row._id.$id).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },

        eidtContentInfo(row) {
            if (row) {
                this.contentInfo = {
                    id: row._id.$id,
                    meeting_id: row.meeting_id,
                    name: row.name,
                    type: row.type,
                    quota_limit: row.quota_limit,
                    quota: row.quota,
                    amount: row.amount,
                    rights: row.rights
                }
            } else {
                this.contentInfo = {
                    id: '',
                    meeting_id: this.meeting_id,
                    name: '',
                    type: '',
                    quota_limit: '',
                    quota: '',
                    amount: '',
                    rights: ''
                }
            }
            this.showContentInfoDialog = true
        },
        saveContent() {
            this.$refs.contentForm.validate((val) => {
                if (val) {
                    updateSponsorContent(this.contentInfo).then((res) => {
                        this.$message.success('保存成功')
                        this.fetchData()
                        this.showContentInfoDialog = false
                    })
                }
            })
        }
    }
})
</script>
<style scoped lang="scss">
.table-name {
    flex: 2;
}

.table-type {
    flex: 1.5;
}

.table-limit {
    flex: 1.5;
}

.table-quota {
    flex: 1.2;
}

.table-amount {
    flex: 1.2;
}

.table-operation {
    flex: 1.5;
    text-align: center;
}



.ql-container {
    height: 160px !important;
}
</style>